/* ============================================================

Main Style Sheet

============================================================ */
/* Reset =================================================== */
html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

article,
aside,
figure,
footer,
header,
hgroup,
nav,
section {
	display: block;
}

/* Variables =================================================== */
/* Mixins =================================================== */
/* Page =================================================== */
@-webkit-viewport {
	width: device-width;
}

@-moz-viewport {
	width: device-width;
}

@-ms-viewport {
	width: device-width;
}

@-o-viewport {
	width: device-width;
}

@viewport {
	width: device-width;
}

html {
	background: white;
	font-size: 62.5%;
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: white;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

body {
	margin: 0;
	padding: 0;
	width: 100%;
	background-color: transparent;
	font-family: "Open Sans", "Lucida Grande", "Lucida Sans Unicode",
		"Lucida Sans", Verdana, Tahoma, sans-serif;
	font-size: 16px;
	font-size: 1.6rem;
	line-height: 8px;
	color: #5d5d5d;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	text-rendering: optimizelegibility;
}

h1,
.h1 {
	margin-bottom: 1em;
	font-size: 26px;
	font-size: 2.6rem;
	line-height: 1em;
	letter-spacing: -0.04em;
	font-weight: normal;
}

h2,
.h2 {
	margin-bottom: 1em;
	font-size: 28px;
	font-size: 2.8rem;
	line-height: 1em;
	letter-spacing: -0.04em;
	font-weight: normal;
}

h3,
.h3 {
	margin-bottom: 1em;
	font-size: 21px;
	font-size: 2.1rem;
	line-height: 1em;
	letter-spacing: -0.04em;
	font-weight: normal;
}

h4,
.h4 {
	margin-bottom: 1em;
	font-size: 18px;
	font-size: 1.8rem;
	line-height: 1em;
	font-weight: normal;
}

h5,
.h5 {
	margin-bottom: 1em;
	font-size: 16px;
	font-size: 1.6rem;
	line-height: 1em;
	letter-spacing: -0.04em;
	font-weight: normal;
}

h6,
.h6 {
	margin-bottom: 0.5em;
	font-size: 14px;
	font-size: 1.4rem;
	font-weight: normal;
	letter-spacing: 1px;
	text-transform: uppercase;
}

p,
li {
	font-size: 16px;
	font-size: 1.6rem;
	line-height: 1.5em;
	margin-bottom: 1.5em;
}

.btn {
	padding: 0.375em 1em;
	border: 1px solid #666;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

.ir {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.wrapper {
	width: 90%;
	padding-left: 5%;
	padding-right: 5%;
	margin-left: 0;
	margin-right: 0;
}

.clearfix {
	*zoom: 1;
}
.clearfix:before,
.clearfix:after {
	display: table;
	content: "";
}
.clearfix:after {
	clear: both;
}

/* Forms =================================================== */
form {
	margin: 0 0 8px 0;
}
form fieldset {
	margin-bottom: 8px;
	padding: 0;
	border-width: 0;
}
form legend {
	display: block;
	width: 100%;
	margin-bottom: 16px;
	*margin-left: -7px;
	padding: 0;
	color: #5d5d5d;
	border: 0;
	border-bottom: 1px solid #8da5cd;
	white-space: normal;
}
form legend small {
	font-size: 14px;
	font-size: 1.4rem;
	color: #bfbfbf;
}
form p {
	margin-bottom: 4px;
}
form ul {
	list-style-type: none;
	margin: 0 0 8px 0;
	padding: 0;
}
form br {
	display: none;
}

label,
input,
button,
select,
textarea {
	font-size: 14px;
	font-size: 1.4rem;
	vertical-align: baseline;
	*vertical-align: middle;
}

input,
button,
select,
textarea {
	font-family: "Open Sans", "Lucida Grande", "Lucida Sans Unicode",
		"Lucida Sans", Verdana, Tahoma, sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

label {
	display: block;
	margin-bottom: 1px;
	font-weight: bold;
	color: #5d5d5d;
	cursor: pointer;
}
label input,
label textarea,
label select {
	display: block;
}

input,
textarea,
select {
	display: inline-block;
	width: 100%;
	font-size: 1.5em;
	padding: 0.5em 5px;
	margin-bottom: 2px;
	background-color: #fffde4;
	border: 1px solid #8da5cd;
	color: #5d5d5d;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}
input:hover,
textarea:hover,
select:hover {
	border-color: #4a6eaa;
}

input[type="image"],
input[type="checkbox"],
input[type="radio"] {
	width: auto;
	height: auto;
	padding: 0;
	margin: 3px 0;
	*margin-top: 0;
	line-height: normal;
	cursor: pointer;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	border: 0 \9;
}

input[type="checkbox"],
input[type="radio"] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
	*width: 13px;
	*height: 13px;
}

input[type="image"] {
	border: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

input[type="file"] {
	width: auto;
	padding: initial;
	line-height: initial;
	border: initial;
	background-color: transparent;
	background-color: initial;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

input[type="button"],
input[type="reset"],
input[type="submit"] {
	width: auto;
	height: auto;
	cursor: pointer;
	*overflow: visible;
}

select,
input[type="file"] {
	height: 34px;
	*margin-top: 4px;
	line-height: 28px;
}

select {
	width: auto;
	background-color: #fffde4;
	font-size: 13px;
	font-size: 1.3rem;
}

select[multiple],
select[size] {
	height: auto;
}

textarea {
	resize: vertical;
	overflow: auto;
	height: auto;
	overflow: auto;
	vertical-align: top;
}

input[type="hidden"] {
	display: none;
}

.radio,
.checkbox {
	padding-left: 18px;
	font-weight: normal;
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
	float: left;
	margin-left: -18px;
}

.radio.inline,
.checkbox.inline {
	display: inline-block;
	padding-top: 5px;
	margin-bottom: 0;
	vertical-align: middle;
}

.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
	margin-left: 10px;
}

.formErrorArrow {
	display: none;
}

.formErrorArrow .formErrorArrowBottom {
	display: none;
}

div.formErrorArrow {
	display: none !important;
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
	-webkit-opacity: 0.5;
	-moz-opacity: 0.5;
	opacity: 0.5;
	cursor: not-allowed;
}

input:focus,
textarea:focus {
	border-color: #2b4063, 40%;
	outline: 0;
	outline: thin dotted \9;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
select:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

::-webkit-input-placeholder {
	font-size: 21px;
	font-size: 2.1rem;
}

input:-moz-placeholder {
	font-size: 21px;
	font-size: 2.1rem;
}

form li {
	*zoom: 1;
}
form li:before,
form li:after {
	display: table;
	content: "";
}
form li:after {
	clear: both;
}

form li span {
	margin: 0;
	padding: 0;
	float: left;
	color: #444;
}

form li fieldset span {
	float: left;
	position: relative;
	margin: 0;
	margin-right: 10px;
	display: block;
}

form li span label,
form li div label {
	margin: 0;
	padding-top: 3px;
	clear: both;
	font-size: 85%;
	line-height: 160%;
	display: block;
	color: #444;
}

form input.checkbox,
form input.radio {
	display: block;
	margin: 4px 0 0 0;
	padding: 0;
	width: 13px;
	height: 13px;
}

form label.desc,
form legend.desc {
	font-size: 95%;
	line-height: 130%;
	font-family: inherit;
	font-style: normal;
	font-weight: bold;
	color: #444;
	margin: 0;
	padding: 0 0 3px 0;
	border: 0;
	display: block;
	white-space: normal;
	width: 100%;
}

form label.choice {
	display: block;
	float: left;
	cursor: pointer;
	line-height: 150%;
	margin: -32px 0 0 20px;
	padding: 0 0 5px 0;
	font-size: 100%;
	font-family: inherit;
	font-style: normal;
	font-weight: normal;
	color: #444;
	clear: none;
}

form label.error {
	float: none;
	color: red;
	padding-left: 0.5em;
	vertical-align: top;
}

form li.buttons {
	text-align: center;
}

form .field.short {
	width: 80px;
}

form .field.medium {
	width: 120px;
}

form .field.large {
	width: 200px;
}

form .symbol {
	padding: 0 0.25em;
	color: #909090;
	line-height: 4em;
}

form li.horiz {
	margin-bottom: 0.5em;
	*zoom: 1;
}
form li.horiz:before,
form li.horiz:after {
	display: table;
	content: "";
}
form li.horiz:after {
	clear: both;
}
form li.horiz fieldset {
	display: block;
	width: 102.08333%;
	margin: 0 -1.04167%;
	*zoom: 1;
}
form li.horiz fieldset:before,
form li.horiz fieldset:after {
	display: table;
	content: "";
}
form li.horiz fieldset:after {
	clear: both;
}
form li.horiz .desc {
	display: inline;
	float: left;
	width: 100%;
	margin: 0 1.04167%;
	margin-bottom: 0.5em;
}
form li.horiz div {
	display: inline;
	float: left;
	width: 22.91667%;
	margin: 0 1.04167%;
}
form li.horiz div span {
	float: left;
	position: relative;
}

form li.horiz select {
	width: 100%;
	height: 2.5em;
	margin-bottom: 1.5em;
}

form select[disabled] {
	color: #aaa;
}

#residenceStateAndZip {
	display: none;
	padding-top: 5px;
}

#validationErrors {
	color: #f00;
	font-weight: bold;
	text-align: center;
	padding-top: 3px;
}

#thankyouresponse,
#sorryresponse {
	display: none;
}

.pending-header {
	margin-top: 1em;
	font-size: 2rem;
	margin-bottom: 0.5em;
	font-weight: bold;
	text-align: center;
	color: #0080ff;
}

#pendingresponse {
	display: none;
}
#pendingresponse h1 {
	margin-bottom: 1em;
}
#pendingresponse h3 {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}
#pendingresponse span p {
	margin-top: 1em;
}

#mstep-second-step #mstep-third-step {
	display: none;
}

.header-cta {
	margin-bottom: 0.5em;
}

.legal {
	margin-bottom: 1em;
}
.legal p {
	font-size: 11px;
	font-size: 1.1rem;
}

.legal .legal-text {
	font-size: 1rem;
	font-weight: 100;
}

.formErrorArrow {
	display: none;
}

.inputError {
	border: 1px solid #ff0000;
	-webkit-box-shadow: 0 0 5px #ff0000;
	-moz-box-shadow: 0 0 5px #ff0000;
	box-shadow: 0 0 5px #ff0000;
}

#unsubresponse {
	color: red;
	text-align: center;
	margin-bottom: 1.5em;
}

#unsubformdiv {
	width: 464px;
}

#mstep-fourth-step input[type="radio"] {
	float: left;
	margin-right: 0.5rem;
	margin-bottom: 0.5em;
}

.alt-form-header {
	display: none;
}

#pi-form,
#offer-2-form {
	display: none;
}

#mstep-fourth-step ol label.desc.radio-four {
	margin-bottom: 0.5em;
}

.button-offer {
	background: #0080ff;
	font-size: 1.8rem;
	border-radius: 10px;
	border: 0;
	padding: 1em;
	color: #fff;
	font-weight: bold;
}

.button-offer-link {
	color: #fff;
	font-weight: bold;
}

.button-offer-link:hover {
	color: #fff;
}

/*	==========================================================================
	Globals
	========================================================================== */
/*	Globals: Base Opinionated Defaults
	========================================================================== */
html,
button,
input,
select,
textarea {
	color: #5d5d5d;
	font-family: "Open Sans", "Lucida Grande", "Lucida Sans Unicode",
		"Lucida Sans", Verdana, Tahoma, sans-serif;
}

body {
	font-size: 16px;
	line-height: 1em;
	width: 100%;
	*zoom: 1;
}
body:before,
body:after {
	display: table;
	content: "";
}
body:after {
	clear: both;
}

::-moz-selection {
	background: #b3d4fc;
	text-shadow: none;
}

::selection {
	background: #b3d4fc;
	text-shadow: none;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

img {
	vertical-align: middle;
	max-width: 100%;
	height: auto;
	width: 100%;
}

img[class*="align"],
img[class*="wp-image-"] {
	height: auto;
	/* Make sure images with WordPress-added height and width >attributes are scaled correctly */
}

img.size-full {
	max-width: 100%;
	width: auto;
	/* Prevent stretching of full-size images with height and >>width attributes in IE8 */
}

#map_canvas img,
.map_canvas img {
	max-width: none !important;
}

a {
	color: #013568;
	text-decoration: none;
	-webkit-transition: color 0.1s ease-out;
	-moz-transition: color 0.1s ease-out;
	-ms-transition: color 0.1s ease-out;
	-o-transition: color 0.1s ease-out;
	transition: color 0.1s ease-out;
}
a:hover {
	color: #011b35;
}

/* Chrome Frame prompt  */
.chromeframe {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}

/*	Globals: Helper Classes
	========================================================================== */
.ir {
	background-color: transparent;
	border: 0;
	overflow: hidden;
	/* IE 6/7 fallback */
	*text-indent: -9999px;
}

.ir:before {
	content: "";
	display: block;
	width: 0;
	height: 100%;
}

.hidden {
	display: none !important;
	visibility: hidden;
}

.visuallyhidden {
	overflow: hidden;
	position: absolute;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	position: static;
	clip: auto;
	height: auto;
	width: auto;
	margin: 0;
	overflow: visible;
}

.invisible {
	visibility: hidden;
}

.clearfix {
	*zoom: 1;
}
.clearfix:before,
.clearfix:after {
	display: table;
	content: "";
}
.clearfix:after {
	clear: both;
}

.wrapper {
	padding-left: 5%;
	padding-right: 5%;
	width: auto;
	position: relative;
}

.showforshortform {
	display: none;
}

.radio-item {
	display: inline-block;
	position: relative;
	padding: 0 6px;
	margin: 10px 0 0;
}

.radio-item input[type="radio"] {
	position: absolute;
	visibility: hidden;
}

.radio-item label {
	color: #000;
	font-weight: bold;
}

.radio-item label:before {
	content: " ";
	display: inline-block;
	position: relative;
	top: 5px;
	margin: 0 5px 0 0;
	width: 20px;
	height: 20px;
	border-radius: 15px;
	border: 2px solid #000;
	background-color: transparent;
}

.radio-item input[type="radio"]:checked + label:after {
	line-height: 14px;
	border-radius: 14px;
	width: 14px;
	height: 14px;
	position: absolute;
	top: 10px;
	margin-left: 5px;
	content: " ";
	display: block;
	background: blue;
	color: red;
}

.radio-header {
	margin-top: 1em !important;
}

/* 	==========================================================================
	320 AND +
	========================================================================== */
#masthead,
#main,
#steps,
#signup,
#logos {
	*zoom: 1;
}
#masthead:before,
#masthead:after,
#main:before,
#main:after,
#steps:before,
#steps:after,
#signup:before,
#signup:after,
#logos:before,
#logos:after {
	display: table;
	content: "";
}
#masthead:after,
#main:after,
#steps:after,
#signup:after,
#logos:after {
	clear: both;
}

#masthead {
	padding: 1em 0 2em;
}
#masthead .logo {
	display: inline;
	float: left;
	width: 47.91667%;
	margin: 0 1.04167%;
	padding-top: 1em;
}
#masthead .logo img {
	width: 200px;
}
#masthead aside {
	display: inline;
	float: left;
	width: 4.16667%;
	margin: 0 1.04167%;
	margin-left: 44.79167%;
	text-align: right;
}

#main hgroup {
	text-align: center;
	margin-bottom: 1em;
}
#main hgroup h1 {
	font-weight: 900;
	margin-bottom: 0.5em;
	font-size: 2rem;
	color: #386098;
}
#main hgroup h3 {
	letter-spacing: 0;
	font-size: 16px;
	font-size: 1.6rem;
}

#steps {
	margin-bottom: 4.5em;
	padding-top: 1.5em;
}
#steps ul {
	background: #e6e6e6;
	height: 1em;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	clear: both;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
}
#steps li {
	flex-grow: 1;
	text-align: center;
	position: relative;
	list-style: none;
	width: 33%;
}
#steps li:first-child {
}
#steps li span {
	display: block;
	width: 2em;
	height: 1.5em;
	background: #e6e6e6;
	-webkit-border-radius: 4em;
	-moz-border-radius: 4em;
	border-radius: 4em;
	padding-top: 0.5em;
	line-height: 1em;
	font-size: 28px;
	font-size: 2.8rem;
	font-weight: 600;
	color: gray;
	position: absolute;
	left: 50%;
	margin-left: -1em;
	top: -0.7em;
}
#steps li span:before {
	display: block;
	content: "";
	width: 1.5em;
	height: 1.25em;
	background: #fff;
	-webkit-border-radius: 4em;
	-moz-border-radius: 4em;
	border-radius: 4em;
	padding-top: 0.25em;
	line-height: 1em;
	font-size: 28px;
	font-size: 2.8rem;
	font-weight: 600;
	color: gray;
	position: absolute;
	left: 50%;
	margin-left: -0.75em;
	top: 0.25em;
}
#steps li:nth-child(1) span:before {
	content: "1";
}
#steps li:nth-child(2) span:before {
	content: "2";
}
#steps li:nth-child(3) span:before {
	content: "3";
}
#steps li:nth-child(4) span:before {
	content: "4";
}
#steps li:nth-child(5) span:before {
	content: "5";
}
#steps li strong {
	display: block;
	position: relative;
	text-align: center;
	margin-top: 2.75em;
	font-size: 0.8em;
}
#steps li.current span:before {
	background: #386098;
	color: #fff;
	-webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2),
		0 0 4px 1px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2),
		0 0 4px 1px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2), 0 0 4px 1px rgba(0, 0, 0, 0.2);
}

#signup {
	border-bottom: 6px solid #ccc;
	margin-bottom: 2em;
}
#signup form {
	display: inline;
	float: left;
	width: 97.91667%;
	margin: 0 1.04167%;
}
#signup form fieldset ol {
	display: block;
	width: 102.08333%;
	margin: 0 -1.04167%;
	*zoom: 1;
	*zoom: 1;
}
#signup form fieldset ol:before,
#signup form fieldset ol:after {
	display: table;
	content: "";
}
#signup form fieldset ol:after {
	clear: both;
}
#signup form fieldset ol:before,
#signup form fieldset ol:after {
	display: table;
	content: "";
}
#signup form fieldset ol:after {
	clear: both;
}
#signup form fieldset li {
	display: inline;
	float: left;
	width: 47.91667%;
	margin: 0 1.04167%;
}
#signup form fieldset li.full,
#signup form fieldset li.horiz,
#signup form fieldset li.phone,
#signup form fieldset li.date {
	display: inline;
	float: left;
	width: 97.91667%;
	margin: 0 1.04167%;
}
#signup form fieldset li input,
#signup form fieldset li select {
	margin-bottom: 1.15em;
	font-size: 1.25em;
}

#signup form fieldset li textarea {
	font-size: 1.5em;
}

#signup form fieldset li select {
	height: 2.5em;
	width: 100%;
}

#signup form footer {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}
#signup form footer p {
	font-size: 11px;
	font-size: 1.1rem;
}
#signup .btn-holder {
	text-align: center;
	padding: 1em 0;
}

.modal_questions .btn-holder {
	text-align: center;
}

#signup .btn-holder button,
.modal_questions .btn-holder button {
	width: 80%;
	background: #688433;
	font-size: 28px;
	font-size: 2.8rem;
	border: 0;
	outline: none;
	-webkit-border-radius: 40px;
	-moz-border-radius: 40px;
	border-radius: 40px;
	font-weight: 700;
	color: #eee;
	/* padding: 0.5em 1em 0.5em 2.5em; */
	cursor: pointer;
	position: relative;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	padding-top: 0.4em;
	padding-bottom: 0.4em;
}

#signup .btn-holder button:hover,
.modal_questions .btn-holder button:hover {
	background: #688433;
	color: #fff;
}

#signup .btn-holder-alt button {
	width: 77%;
	font-size: 14px;
	font-size: 1.4rem;
	margin-bottom: 1em;
}

#logos {
	margin-top: 1em;
	margin-bottom: 1em;
}
#logos ol {
	display: block;
	width: 102.08333%;
	margin: 0 -1.04167%;
	*zoom: 1;
	*zoom: 1;
}
#logos ol:before,
#logos ol:after {
	display: table;
	content: "";
}
#logos ol:after {
	clear: both;
}
#logos ol:before,
#logos ol:after {
	display: table;
	content: "";
}
#logos ol:after {
	clear: both;
}
#logos li {
	display: inline;
	float: left;
	width: 22.91667%;
	margin: 0 1.04167%;
}
#logos h4 {
	overflow: hidden;
	position: absolute;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}
#logos h4.focusable:active,
#logos h4.focusable:focus {
	position: static;
	clip: auto;
	height: auto;
	width: auto;
	margin: 0;
	overflow: visible;
}

#testimonial {
	display: none;
	position: relative;
}
#testimonial div p {
	vertical-align: top;
	position: absolute;
	top: 30%;
	right: 0;
	z-index: 100;
	line-height: 1.5em;
	text-align: right;
	font-size: 13px;
	font-size: 1.3rem;
}
#testimonial div p strong {
	display: block;
	font-size: 15px;
	font-size: 1.5rem;
}
#testimonial div img {
	margin-top: 1em;
}

.disclaimer {
	margin-bottom: 2em;
}
.disclaimer p {
	font-size: 12px;
	font-size: 1.2rem;
	text-align: center;
}
.disclaimer a {
	color: #5d5d5d;
}
.disclaimer a:hover {
	color: #1d1d1d;
}

.processing-ellipses img {
	width: 10%;
}

footer nav {
	*zoom: 1;
	text-align: center;
}
footer nav:before,
footer nav:after {
	display: table;
	content: "";
}
footer nav:after {
	clear: both;
}
footer nav ul {
	padding: 0;
	margin: 0 auto 2em;
}
footer nav li {
	display: inline;
	font-size: 12px;
	font-size: 1.2rem;
}
footer nav a {
	padding: 0.5em 2em;
	color: #013568;
}

.modal_questions input[type="radio"] {
	float: left;
}

.modal_questions label {
	padding-left: 16px;
}

#continuationresponse,
#sorryresponse,
#thankyouresponse {
	display: none;
}

#bannercontainer a {
	display: block;
	margin: 0.5em 0;
	clear: left;
}

.nonmobilebanner {
	display: none;
}

.mobilebanner {
	display: block;
}

.exit-links a {
	color: #688433;
	font-weight: 700;
	-webkit-transition: color 0.1s ease-out;
	-moz-transition: color 0.1s ease-out;
	-ms-transition: color 0.1s ease-out;
	-o-transition: color 0.1s ease-out;
	transition: color 0.1s ease-out;
}
.exit-links a:hover {
	color: #85a941;
}

#attorney-name,
#injury-reasons {
	display: none;
}

#signup form fieldset li.full.other-reason {
	list-style-type: none;
	display: none;
}

#mobile-phone-cta {
	display: block !important;
}

#mobile-phone-cta button a {
	color: #fff;
}

#nonmobile-phone-cta {
	display: none !important;
}

.link-enhance {
	text-decoration: underline;
}

@media only screen and (min-width: 480px) {
	#mobile-phone-cta {
		display: none !important;
	}

	#nonmobile-phone-cta {
		display: block !important;
	}
}

.offer-emphasis {
	border-style: solid;
	border-width: 0.75em;
	border-color: #f2f2f2;
	padding: 0.5em;
	line-height: 1.5em;
}

.pending-mobile {
	display: none;
}

@media print {
	/* Print =================================================== */
	* {
		background: transparent !important;
		color: black !important;
		box-shadow: none !important;
		text-shadow: none !important;
		filter: none !important;
		-ms-filter: none !important;
	}

	@page {
		margin: 0.5cm;
	}

	h2,
	h3 {
		orphans: 3;
		widows: 3;
		page-break-after: avoid;
	}

	p {
		orphans: 3;
		widows: 3;
	}

	pre,
	blockquote {
		border: 1px solid gray;
		page-break-inside: avoid;
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " (" attr(href) ")";
	}

	a[href^="javascript:"]:after,
	a[href^="#"]:after {
		content: "";
	}

	img {
		max-width: 100% !important;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	tr {
		page-break-inside: avoid;
	}
}
@media only screen and (min-width: 480px) {
	/* 480 =================================================== */
	.nonmobilebanner {
		display: block;
	}

	.mobilebanner {
		display: none;
	}

	#signup .btn-holder-alt button {
		width: 77%;
		font-size: 14px;
		font-size: 1.4rem;
		margin-bottom: 1em;
	}
}
@media only screen and (min-width: 600px) {
	/* 600 =================================================== */
	#signup .btn-holder button,
	.modal_questions .btn-holder button {
		font-size: 33px;
		font-size: 3.3rem;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		max-width: 90%;
	}

	#signup .btn-holder-alt button {
		width: 77%;
		font-size: 14px;
		font-size: 1.4rem;
		margin-bottom: 1em;
	}

	#main hgroup h1 {
		font-weight: 600;
		margin-bottom: 0.5em;
		font-size: 3rem;
	}
}
@media only screen and (min-width: 768px) {
	/* 768 =================================================== */
	#signup form {
		display: inline;
		float: left;
		width: 47.91667%;
		margin: 0 1.04167%;
		margin-left: 7.29167%;
	}
	#signup form fieldset ol {
		display: block;
		width: 104.16667%;
		margin: 0 -2.08333%;
		*zoom: 1;
	}
	#signup form fieldset ol:before,
	#signup form fieldset ol:after {
		display: table;
		content: "";
	}
	#signup form fieldset ol:after {
		clear: both;
	}
	#signup form fieldset li {
		display: inline;
		float: left;
		width: 45.83333%;
		margin: 0 2.08333%;
	}
	#signup form fieldset li.full,
	#signup form fieldset li.horiz,
	#signup form fieldset li.phone,
	#signup form fieldset li.date {
		display: inline;
		float: left;
		width: 95.83333%;
		margin: 0 2.08333%;
	}
	#signup #logos ul {
		display: block;
		width: 104.16667%;
		margin: 0 -2.08333%;
		*zoom: 1;
	}
	#signup #logos ul:before,
	#signup #logos ul:after {
		display: table;
		content: "";
	}
	#signup #logos ul:after {
		clear: both;
	}
	#signup #logos ul li {
		display: inline;
		float: left;
		width: 20.83333%;
		margin: 0 2.08333%;
	}

	#testimonial {
		display: inline;
		display: inline;
		float: left;
		width: 41.66667%;
		margin: 0 1.04167%;
	}
	#testimonial div p {
		right: -10%;
	}
}
@media only screen and (min-width: 992px) {
	/* 992 =================================================== */
	.wrapper {
		width: 960px;
		padding-left: 0;
		padding-right: 0;
		margin-left: auto;
		margin-right: auto;
	}

	#testimonial {
		display: inline;
		float: left;
		width: 35.41667%;
		margin: 0 1.04167%;
	}
	#testimonial div p {
		right: -20%;
	}

	#signup form {
		margin-left: 13.54167%;
	}

	#signup .btn-holder button {
		/* font-size: 44px;
		font-size: 4.4rem; */
	}

	#signup .btn-holder-alt button {
		width: 49%;
		font-size: 14px;
		font-size: 1.4rem;
		margin-bottom: 0;
	}

	.pending-header {
		margin-top: 0;
		margin-bottom: 1em;
		font-size: 2.6rem;
	}

	.pending-mobile {
		display: block;
	}
}
@media only screen and (min-width: 1382px) {
	/* 1382 =================================================== */
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	only screen and (min--moz-device-pixel-ratio: 1.5),
	only screen and (min-device-pixel-ratio: 1.5) {
	/* 2x =================================================== */
}
